<script>
import PageAlert from './../components/PageAlert.vue'
import DataLoading from './../components/DataLoading.vue'

export default {
  name: 'HeaderSection',
  components: {
    PageAlert,
    DataLoading,
  }, 
  data() {
    return {
      loading:false,
      auth_name:"",
      MyPageAlert:{"status":"","message":""}

    }
  },
  mounted() {
    this.auth_name=localStorage.getItem("auth_name");
    this.token=localStorage.getItem("access_token");
  }, 
  methods: {
     async Logout(){
      try {
        this.loading=true;
        const response = await this.$axios.get("/auth/logout");
        response;
        localStorage.removeItem("access_token","");
        this.$router.push({ name: "Login" })       
        
      } catch (error) {
        this.MyPageAlert.status="error";
        this.MyPageAlert.message=error.message;
        this.loading=false;
      }  
 
     }
  }
}
</script>
<template>

<DataLoading v-if="loading"/>
<PageAlert v-if="MyPageAlert.message"/>
<div :class="!$parent.kt_aside2?'kt-header-menu-wrapper':'kt-header-menu-wrapper kt-header-menu-wrapper--on'" id="kt_header_menu_wrapper">
   <div id="kt_header_menu" class="kt-header-menu kt-header-menu-mobile  kt-header-menu--layout-default ">
      <ul class="kt-menu__nav ">
         <li class="kt-menu__item  kt-menu__item--active" >
            <RouterLink :to="{name:'Home'}" class="kt-menu__link"><span class="kt-menu__link-text"><strong>Portal</strong></span></RouterLink>
         </li>
         <li class="kt-menu__item kt-menu__item--rel">
            <RouterLink :to="{name:'Sale'}" class="kt-menu__link">
            <span class="kt-menu__link-text"> <strong>Perakande Satış</strong></span>
            </RouterLink>
         </li>
      </ul>
   </div>
</div>
<div class="kt-header__topbar">
   <div class="kt-header__topbar-item kt-header__topbar-item--user">
      <div class="kt-header__topbar-wrapper" data-bs-toggle="dropdown" data-bs-offset="0px,0px">
         <div class="kt-header__topbar-user">
            <span class="kt-header__topbar-username kt-hidden-mobile">{{auth_name}}</span>
            <i class="fa fa-user"></i>            
         </div>

      </div>
      <div class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
         <div class="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x" style="background-image: url('assets/media/misc/bg-1.jpg')">
            <div class="kt-user-card__name">
               {{auth_name}}
            </div>
         </div>
         <div class="kt-notification">
            <div class="kt-notification__custom kt-space-between">
               
            
                <button class="btn btn-label btn-label-danger btn-sm btn-bold right" @click="Logout()">
                    Çıkış
                </button>
        

                
                <button data-bs-dismiss="dropdown" type="button" class="btn btn-label btn-label-warning btn-sm btn-bold" data-bs-toggle="modal" data-bs-target="#sifre_degistir"> Şifre Değiştir</button>
            </div>
         </div>
      </div>
   </div>
</div>

</template>