<script>
import DataLoading from './components/DataLoading.vue'
import LeftMenu from './components/LeftMenu.vue'
import HeaderSection from './components/HeaderSection.vue'
import FooterSection from './components/FooterSection.vue'

export default {
  name: 'App',
  data(){
    return {
      kt_aside_minimize:false,
      kt_aside:false,
      kt_aside2:false,
      PageLayout:"default",
      CONNECTED:false,
      loading:false
    }
  },
  components: {
    LeftMenu,
    HeaderSection,
    FooterSection,
    DataLoading,
  },
  watch: {
    '$route' (to, ) {
      //console.log(to.name+from.name+this.$route.name);
      this.layoutChanged(to.name);
    }
  }, 
  mounted(){ 
      this.layoutChanged(this.$route.name); 
      document.addEventListener('click', this.myClickHandler);      
  },
  methods:{
    myClickHandler(event) {
      
      if(event.target.id!="kt_aside" && event.target.id!="kt_aside_mobile_toggler" && event.target.id!="kt_header_mobile_toggler"){
        this.kt_aside=false;
        this.kt_aside2=false;
      }
    },
    layoutChanged(routeName){
      if(routeName=="Login" || !this.checkToken()){
        this.PageLayout = "login";
      }
      else{
        if(routeName=="Sale"){
          this.PageLayout = "sale";
        } 
        else{
          this.PageLayout = "default";
        } 
      }      
    },
    apptest(){
     console.log("TEST");
    },
    async checkToken(){
      try {
        this.loading=true;
        const response = await this.$axios.get('/auth/me');
        response;
        this.CONNECTED=true;
        this.loading=false;
        return true;
      } catch (error) {
        this.CONNECTED=false;
        this.loading=false;
        error;
      }
      return false;
    }
  }
}
</script>

<template>
<div id="app" :class="kt_aside_minimize?'kt-quick-panel--right kt-demo-panel--right kt-offcanvas-panel--right kt-header--fixed kt-header-mobile--fixed kt-subheader--enabled kt-subheader--fixed kt-subheader--solid kt-aside--enabled kt-aside--fixed kt-aside--minimize':'kt-quick-panel--right kt-demo-panel--right kt-offcanvas-panel--right kt-header--fixed kt-header-mobile--fixed kt-subheader--enabled kt-subheader--fixed kt-subheader--solid kt-aside--enabled kt-aside--fixed'">
   
   <div v-if="CONNECTED">
   <div v-if="PageLayout=='default'" data-page="urunler" class="kt-quick-panel--right kt-demo-panel--right kt-offcanvas-panel--right kt-header--fixed kt-header-mobile--fixed kt-subheader--enabled kt-subheader--fixed kt-subheader--solid kt-aside--enabled kt-aside--fixed kt-page--loading">
      <div id="app2">
         <div id="kt_header_mobile" class="kt-header-mobile  kt-header-mobile--fixed ">
            <div class="kt-header-mobile__logo">
               <RouterLink :to="{name:'Home'}">
                <img alt="Logo" src="@/assets/media/logos/logo-light.png" />
               </RouterLink>
            </div>
            <div class="kt-header-mobile__toolbar">
               <button @click="kt_aside2=!kt_aside2;kt_aside=false" class="kt-header-mobile__toggler kt-header-mobile__toggler--left" id="kt_aside_mobile_toggler"><span></span></button>
               <button @click="kt_aside=!kt_aside;kt_aside2=false" class="kt-header-mobile__toggler" id="kt_header_mobile_toggler"><span></span></button>
               <!--
               <button @click="kt_aside2=!kt_aside2;kt_aside=false" class="kt-header-mobile__topbar-toggler" id="kt_header_mobile_topbar_toggler"><i class="flaticon-more"></i></button>
                -->
            </div>
         </div>
         <div class="kt-grid kt-grid--hor kt-grid--root">
         
            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
               <div :class="!kt_aside?'kt-aside  kt-aside--fixed  kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop':'kt-aside  kt-aside--fixed  kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop kt-aside--on'" id="kt_aside">
                   <LeftMenu/>
               </div>
               <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                  <div id="kt_header" class="kt-header kt-grid__item  kt-header--fixed ">
                     <HeaderSection/>
                  </div>
                  <div>
                     <router-view :key="$route.fullPath" />
                  </div>          
                  <FooterSection/>
               </div>
            </div>
         </div>
         <div id="kt_scrolltop" class="kt-scrolltop">
            <i class="fa fa-arrow-up"></i>
         </div>
      </div>
   </div>
   <div v-if="PageLayout=='sale'">
    <router-view :key="$route.fullPath" />
   </div> 
  </div>   
  <div v-if="PageLayout=='login'">
   <router-view :key="$route.fullPath" />
  </div>
  <DataLoading v-if="loading"/>
</div>   
</template>